export const environment = {
  apiUrl: 'https://us-central1-germinar-dev.cloudfunctions.net/app',
  firebase: {
    apiKey: "AIzaSyCXMB3lRM2dS5vE0KkpGAxrKETcHdpqhkc",
    authDomain: "germinar-dev.firebaseapp.com",
    projectId: "germinar-dev",
    storageBucket: "germinar-dev.appspot.com",
    messagingSenderId: "494888335565",
    appId: "1:494888335565:web:b029d466f7824bb9bc9184"
  },
  production: false,
};